import moment from 'moment';

const moduleName = 'FORT';

const addNewOrderEvent = 'Add new order';
const addNewFixtureEvent = 'Add new fixture';
const openAuditHistoryEvent = 'Open Audit History';
const openAnalyticsEvent = 'Open Analytics';
const openAdvancedAnalyticsEvent = 'Open Advanced Analytics';
const deleteItemEvent = 'Delete order/fixture';
const reInstateDeletedItemEvent = 'Re-instate a deleted order/fixture';
const exportToEmailEvent = 'Export to Email';
const exportToExcelEvent = 'Export to Excel';
const convertOrderToFixture = 'Convert order to fixture';
const changeVisibilityEvent = 'Change of visibility status';
const changeConfidentialityEvent = 'Change of confidentiality status';
const dataFilterAppliedEvent = 'Data Filter Applied';
const deliveryTermColumnSelectEvent = 'Delivery term column select';
const deliveryTermValueChangedEvent = 'Delivery Term edit';

const createLayoutEvent = 'Create Layout';
const editLayoutNameEvent = 'Edit Layout Name';
const deleteLayoutEvent = 'Delete Layout';
const switchLayoutEvent = 'Switching Layouts';
const saveLayoutEvent = 'Saving Layout';
const revertLayoutEvent = 'Revert Layout';

const menuNewUItoggleClick = 'Menu - New UI toggle click';
const menuDensityChange = 'Menu - Density settings change';

export const createAddNewOrderEvent = (
    orderId,
    groupOnly,
    privacy,
    isIndividual,
    fortGroup,
    userName,
    methodOfAddition,
    datasetId
) => {
    const orderType = getItemType('Order', groupOnly, privacy, isIndividual);
    const payload = createAddNewEntityPayload(
        orderId,
        orderType,
        fortGroup,
        userName,
        methodOfAddition,
        datasetId
    );

    return {
        name: createEventName(addNewOrderEvent),
        payload,
    };
};

export const createAddNewFixtureEvent = (
    fixtureId,
    groupOnly,
    privacy,
    isIndividual,
    fortGroup,
    userName,
    methodOfAddition,
    datasetId
) => {
    const fixtureType = getItemType(
        'Fixture',
        groupOnly,
        privacy,
        isIndividual
    );
    const payload = createAddNewEntityPayload(
        fixtureId,
        fixtureType,
        fortGroup,
        userName,
        methodOfAddition,
        datasetId
    );

    return {
        name: createEventName(addNewFixtureEvent),
        payload,
    };
};

export const createDeleteItemEvent = (
    itemId,
    itemName,
    groupOnly,
    privacy,
    fortGroup,
    userName
) => {
    const itemType = getItemType(itemName, groupOnly, privacy);
    const payload = createPayload(itemId, itemType, fortGroup, userName);

    return {
        name: createEventName(deleteItemEvent),
        payload,
    };
};

export const createReInstateDeletedItemEvent = (
    itemId,
    itemName,
    groupOnly,
    privacy,
    fortGroup,
    userName
) => {
    const itemType = getItemType(itemName, groupOnly, privacy);
    const payload = createPayload(itemId, itemType, fortGroup, userName);

    return {
        name: createEventName(reInstateDeletedItemEvent),
        payload,
    };
};

export const createOpenAuditHistoryEvent = (
    itemId,
    itemName,
    groupOnly,
    privacy,
    fortGroup,
    userName
) => {
    const itemType = getItemType(itemName, groupOnly, privacy);
    const payload = createPayload(itemId, itemType, fortGroup, userName);

    return {
        name: createEventName(openAuditHistoryEvent),
        payload,
    };
};

export const createOpenAnalyticsEvent = (fortGroup, userName) => {
    const payload = createPayloadWithoutId('Fixture', fortGroup, userName);

    return {
        name: createEventName(openAnalyticsEvent),
        payload,
    };
};

export const createOpenAdvancedAnalyticsEvent = (fortGroup, userName) => {
    const payload = createPayloadWithoutId('Fixture', fortGroup, userName);

    return {
        name: createEventName(openAdvancedAnalyticsEvent),
        payload,
    };
};

export const createCreateLayoutEvent = (
    userName,
    layoutName,
    layoutProperties
) => {
    const payload = createLayoutEventsPayload(
        userName,
        layoutName,
        layoutProperties
    );

    return {
        name: createEventName(createLayoutEvent),
        payload,
    };
};

export const createEditLayoutNameEvent = (userName, layoutName) => {
    const payload = createLayoutEventsPayload(userName, layoutName);

    return {
        name: createEventName(editLayoutNameEvent),
        payload,
    };
};

export const createDeleteLayoutEvent = (userName, layoutName, isTemplate) => {
    const payload = createLayoutEventsPayload(userName, layoutName, {
        isTemplate,
    });

    return {
        name: createEventName(deleteLayoutEvent),
        payload,
    };
};

export const createSwitchLayoutEvent = (userName, layoutName, isTemplate) => {
    const payload = createLayoutEventsPayload(userName, layoutName, {
        isTemplate,
    });

    return {
        name: createEventName(switchLayoutEvent),
        payload,
    };
};

export const createSaveLayoutEvent = (
    userName,
    layoutName,
    layoutProperties
) => {
    const payload = createLayoutEventsPayload(
        userName,
        layoutName,
        layoutProperties
    );

    return {
        name: createEventName(saveLayoutEvent),
        payload,
    };
};

export const createRevertLayoutEvent = (userName, layoutName, isTemplate) => {
    const payload = createLayoutEventsPayload(userName, layoutName, {
        isTemplate,
    });

    return {
        name: createEventName(revertLayoutEvent),
        payload,
    };
};

const createEventName = (eventName) => {
    return `${moduleName}: ${eventName}`;
};

const createPayload = (id, type, fortGroup, userName) => {
    return {
        id,
        type,
        userName,
        createdDateTime: moment().utc().format(),
        fortGroup,
    };
};

const createPayloadWithoutId = (type, fortGroup, userName) => {
    return {
        type,
        userName,
        createdDateTime: moment().utc().format(),
        fortGroup,
    };
};

const createAddNewEntityPayload = (
    id,
    type,
    fortGroup,
    userName,
    methodOfAddition,
    datasetId
) => {
    return {
        ...createPayload(id, type, fortGroup, userName),
        methodOfAddition,
        datasetId,
    };
};

const createLayoutEventsPayload = (userName, layoutName, layoutProperties) => {
    let payload = {
        userName,
        createdDateTime: moment().utc().format(),
        name: layoutName,
    };

    return layoutProperties ? { ...payload, ...layoutProperties } : payload;
};

export const createExportToEmailEvent = (
    userName,
    type,
    noOfRowsExported,
    includesConfidentialData
) => {
    return {
        name: createEventName(exportToEmailEvent),
        payload: createExportEventsPayload(
            userName,
            type,
            noOfRowsExported,
            includesConfidentialData
        ),
    };
};

export const createExportToExcelEvent = (
    userName,
    type,
    noOfRowsExported,
    includesConfidentialData,
    includesGroupHeadings
) => {
    const payload = {
        ...createExportEventsPayload(
            userName,
            type,
            noOfRowsExported,
            includesConfidentialData
        ),
        includesGroupHeadings,
    };

    return {
        name: createEventName(exportToExcelEvent),
        payload,
    };
};

export const createConvertOrderToFixtureEvent = (
    groupOnly,
    privacy,
    fixtureId,
    orderId,
    fortGroup,
    userName
) => {
    const fixtureType = getItemType('Fixture', groupOnly, privacy);
    const payload = {
        fixtureType,
        fixtureId,
        orderId,
        userName,
        createdDateTime: moment().utc().format(),
        fortGroup,
    };

    return {
        name: createEventName(convertOrderToFixture),
        payload,
    };
};

const createExportEventsPayload = (
    userName,
    type,
    noOfRowsExported,
    includesConfidentialData
) => {
    return {
        userName,
        createdDateTime: moment().utc().format(),
        type,
        noOfRowsExported,
        includesConfidentialData,
    };
};

export const createChangeVisibilityItemEvent = (props) => {
    const {
        itemId,
        itemName,
        groupOnly,
        privacy,
        isIndividual,
        fortGroup,
        userName,
        datasetId,
        visibilityStatus,
        previousVisibilityStatus,
    } = props;
    const itemType = getItemType(itemName, groupOnly, privacy, isIndividual);
    const payload = {
        ...createPayload(itemId, itemType, fortGroup, userName),
        datasetId,
        visibilityStatus,
        previousVisibilityStatus,
    };

    return {
        name: createEventName(changeVisibilityEvent),
        payload,
    };
};

export const createChangeConfidentialityItemEvent = (props) => {
    const {
        itemId,
        itemName,
        groupOnly,
        privacy,
        isIndividual,
        fortGroup,
        userName,
        datasetId,
    } = props;
    const itemType = getItemType(itemName, groupOnly, privacy, isIndividual);
    const payload = {
        ...createPayload(itemId, itemType, fortGroup, userName),
        datasetId,
        confidentialityStatus: privacy ? 'Private' : 'Public',
        previousConfidentialityStatus: privacy ? 'Public' : 'Private',
    };

    return {
        name: createEventName(changeConfidentialityEvent),
        payload,
    };
};

export const createDataFilterAppliedEvent = (
    userName,
    tabName,
    datasetId,
    fortGroupName,
    filterName,
    filterValue
) => {
    let filterEventValue = filterValue;
    if (typeof filterValue === 'boolean') {
        filterEventValue = filterValue ? 'On' : 'Off';
    }

    return {
        name: createEventName(dataFilterAppliedEvent),
        payload: {
            tabName,
            userName,
            datasetId,
            fortGroupName,
            filterName,
            filterValue: filterEventValue,
            createdDateTime: moment().utc().format(),
        },
    };
};

export const createDeliveryTermValueChangedEvent = (userName, value) => {
    return {
        name: createEventName(deliveryTermValueChangedEvent),
        payload: {
            userName,
            value: (value?.length ?? 0) > 0 ? value : 'Blank',
        },
    };
};

export const createDeliveryTermVisibilityChangeEvent = (
    userName,
    isVisible
) => {
    return {
        name: createEventName(deliveryTermColumnSelectEvent),
        payload: {
            userName,
            newState: isVisible ? 'Enabled' : 'Disabled',
        },
    };
};

export const createNewUIToggleEvent = (userName, version, toggleValue) => {
    return {
        name: createEventName(menuNewUItoggleClick),
        payload: {
            userName,
            version,
            newTheme: toggleValue ? 'Enabled' : 'Disabled',
        },
    };
};

export const createNewUIDensityChangeEvent = (
    userName,
    compactDensityValue
) => {
    return {
        name: createEventName(menuDensityChange),
        payload: {
            userName,
            densitySettings: compactDensityValue ? 'Compact' : 'Standard',
        },
    };
};

const getItemType = (itemName, groupOnly, privacy, isIndividual = false) => {
    return groupOnly && privacy
        ? `Group Only ${itemName}`
        : isIndividual && privacy
        ? `Individual ${itemName}`
        : privacy
        ? `Private ${itemName}`
        : `Public ${itemName}`;
};
