import { layoutsSelectors } from 'store/feature/layoutsSlice';
import { useAppSelector } from 'store/hooks';
import LayoutIcons from '../../common/LayoutIcons';
import React, { useState } from 'react';
import LayoutManagerPanel from 'components/modal/layouts/manager/LayoutManagerPanel';
import { SavedLayoutModel } from 'store/feature/layoutsSlice/models';
import LayoutChangedSnackbar from 'components/modal/layouts/dialogs/LayoutChangedSnackbar';
import LayoutControls from 'components/common/LayoutControls';
import {
    Badge,
    Box,
    Button,
    Divider,
    ListItemIcon,
    MenuItem,
    Typography,
} from '@mui/material';
import {
    ArrowDropDown,
    CheckCircle,
    Settings,
    Star,
    StarOutline,
} from '@mui/icons-material';
import {
    bindMenu,
    bindTrigger,
    usePopupState,
} from 'material-ui-popup-state/hooks';
import { userSelectors } from 'store/feature/userSlice';
import TinyExpandableArea from 'components/common/TinyExpandableArea';
import useSwitchLayout from 'components/modal/layouts/dialogs/switchLayout';
import { StringFormatter } from 'utils';
import LightTooltip from 'components/common/LightTooltip';
import classNames from 'classnames';
import { LayoutsButtonStack, StyledLayoutDropdownMenu } from './styles';
import useLayoutMenuState from './useLayoutMenuState';
import useGridContext from 'hooks/grid/useGridContext';

const UserLayoutMenuItem: React.FC<{
    model: Pick<SavedLayoutModel, 'id' | 'name' | 'isTemplate' | 'isPreferred'>;
    isSelected: boolean;
    hasPendingChanges: boolean;
    onClick: () => void;
}> = ({ model, isSelected, hasPendingChanges, onClick }) => {
    const icon = model.isPreferred ? <Star /> : <StarOutline />;
    const handleClick = () => {
        if (!isSelected) {
            onClick();
        }
    };

    const isTextLong = model.name.length > 35;
    const formattedText = StringFormatter.trimTitle(model.name, 35);

    return (
        <MenuItem
            dense
            onClick={handleClick}
            disableRipple
            selected={isSelected}
            data-cy="LayoutMenuItem_User"
        >
            <ListItemIcon>{icon}</ListItemIcon>

            <LightTooltip
                title={model.name}
                disableHoverListener={!isTextLong}
                disableInteractive
            >
                <Box className="item-text">
                    <Typography variant="h5">
                        {hasPendingChanges && isSelected && '*'}
                        {formattedText}
                    </Typography>
                </Box>
            </LightTooltip>
            {isSelected && hasPendingChanges && (
                <LayoutControls model={model} />
            )}
        </MenuItem>
    );
};

const TemplateLayoutMenuItem: React.FC<{
    model: Pick<SavedLayoutModel, 'id' | 'name' | 'isTemplate'>;
    isSelected: boolean;
    hasPendingChanges: boolean;
    onClick: () => void;
}> = ({ model, isSelected, hasPendingChanges, onClick }) => {
    const handleClick = () => {
        if (!isSelected) {
            onClick();
        }
    };

    const isTextLong = model.name.length > 35;
    const formattedText = StringFormatter.trimTitle(model.name, 35);

    return (
        <MenuItem
            dense
            onClick={handleClick}
            disableRipple
            selected={isSelected}
            data-cy="LayoutMenuItem_Template"
        >
            <ListItemIcon>
                <LayoutIcons.Template />
            </ListItemIcon>

            <LightTooltip
                title={model.name}
                disableHoverListener={!isTextLong}
                disableInteractive
            >
                <Box className="item-text">
                    <Typography variant="h5">
                        {hasPendingChanges && isSelected && '*'}
                        {formattedText}
                    </Typography>
                </Box>
            </LightTooltip>
            {isSelected && hasPendingChanges && (
                <LayoutControls model={model} />
            )}
        </MenuItem>
    );
};

const TemplateLayoutMenuButton: React.FC<{
    model: Pick<SavedLayoutModel, 'id' | 'name' | 'isTemplate'>;
    isSelected: boolean;
    onClick: () => void;
}> = ({ model, isSelected, onClick }) => {
    const handleClick = () => {
        if (!isSelected) {
            onClick();
        }
    };

    return (
        <Badge
            className={classNames({ 'Fort-selected': isSelected })}
            badgeContent={isSelected && <CheckCircle />}
        >
            <Button
                variant="outlined"
                color="inherit"
                aria-selected={isSelected}
                disableRipple
                onClick={handleClick}
            >
                {model.name}
            </Button>
        </Badge>
    );
};

export interface LayoutsDropdownMenuProps {}

const LayoutsDropdownMenu: React.FC<LayoutsDropdownMenuProps> = () => {
    const { gridType } = useGridContext();
    const isAdmin = useAppSelector(userSelectors.isAdmin);
    const selectedLayout = useAppSelector(layoutsSelectors.selectedLayout);
    const hasPendingChanges = useAppSelector(
        layoutsSelectors.hasPendingChanges
    );
    const layouts = useAppSelector(layoutsSelectors.selectedDatasetLayouts);

    const userLayouts: SavedLayoutModel[] = [];
    const templateLayouts: SavedLayoutModel[] = [];
    layouts.forEach((l) => {
        if (l.isTemplate) {
            templateLayouts.push(l);
        } else {
            userLayouts.push(l);
        }
    });

    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'layout_dropdown_menu',
    });
    const [showLayoutManager, setShowLayoutManager] = useState(false);

    const { switchLayout, switchLayoutDialog } = useSwitchLayout();

    const triggerText =
        (hasPendingChanges ? '*' : '') +
        StringFormatter.trimTitle(selectedLayout.name);

    const menuTitle = gridType + ' layouts';
    const divider = <Divider flexItem />;

    const { state: menuState, toggle: menuToggle } = useLayoutMenuState();

    return (
        <>
            <Box
                sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: theme.spacing(3, 3),
                    '& .MuiButton-root': {},
                    '& .expand-icon': {
                        ...(popupState.isOpen && {
                            transform: 'rotate(180deg)',
                        }),
                    },
                })}
            >
                <Button
                    id="LayoutDropdownMenu_Trigger"
                    {...bindTrigger(popupState)}
                    disableElevation
                    disableRipple
                    variant="outlined"
                    color="info"
                    endIcon={<ArrowDropDown className="expand-icon" />}
                >
                    {triggerText}
                </Button>
            </Box>
            <StyledLayoutDropdownMenu
                {...bindMenu(popupState)}
                MenuListProps={{
                    'aria-labelledby': popupState.popupId,
                    dense: true,
                }}
                slotProps={{ paper: { id: 'LayoutDropdownMenu_Body' } }}
            >
                <Box className="menu-title">
                    {
                        <Typography variant="h4" color="primary">
                            {menuTitle}
                        </Typography>
                    }
                </Box>
                <TinyExpandableArea
                    idx="user"
                    title={
                        <Typography className="menu-subtitle" variant="h6">
                            My layouts
                        </Typography>
                    }
                    expanded={menuState.userSectionExpanded}
                    onChange={menuToggle.userSectionExpanded}
                >
                    {selectedLayout.isTemplate &&
                        !isAdmin &&
                        selectedLayout.selectedLayoutId && (
                            <TemplateLayoutMenuItem
                                model={{
                                    id: selectedLayout.selectedLayoutId,
                                    name: selectedLayout.name,
                                    isTemplate: true,
                                }}
                                isSelected={true}
                                hasPendingChanges={hasPendingChanges}
                                onClick={switchLayout.bind(
                                    null,
                                    selectedLayout.selectedLayoutId
                                )}
                            />
                        )}
                    {userLayouts.map((layout) => (
                        <UserLayoutMenuItem
                            model={layout}
                            isSelected={
                                layout.id === selectedLayout.selectedLayoutId
                            }
                            hasPendingChanges={hasPendingChanges}
                            onClick={switchLayout.bind(null, layout.id)}
                        />
                    ))}
                </TinyExpandableArea>

                {templateLayouts.length > 0 && (
                    <>
                        {isAdmin && (
                            <>
                                {divider}
                                <TinyExpandableArea
                                    idx="templates-admin"
                                    title={
                                        <Typography
                                            className="menu-subtitle"
                                            variant="h6"
                                        >
                                            Layout templates (admin)
                                        </Typography>
                                    }
                                    expanded={
                                        menuState.adminTemplateSectionExpanded
                                    }
                                    onChange={
                                        menuToggle.adminTemplateSectionExpanded
                                    }
                                >
                                    {templateLayouts.map((layout) => (
                                        <TemplateLayoutMenuItem
                                            model={layout}
                                            isSelected={
                                                layout.id ===
                                                selectedLayout.selectedLayoutId
                                            }
                                            hasPendingChanges={
                                                hasPendingChanges
                                            }
                                            onClick={switchLayout.bind(
                                                null,
                                                layout.id
                                            )}
                                        />
                                    ))}
                                </TinyExpandableArea>
                            </>
                        )}

                        {divider}
                        <TinyExpandableArea
                            idx="templates"
                            title={
                                <Typography
                                    className="menu-subtitle"
                                    variant="h6"
                                >
                                    Layout templates
                                </Typography>
                            }
                            expanded={menuState.templateSectionExpanded}
                            onChange={menuToggle.templateSectionExpanded}
                        >
                            <LayoutsButtonStack>
                                {templateLayouts.map((layout) => (
                                    <TemplateLayoutMenuButton
                                        model={layout}
                                        isSelected={
                                            layout.id ===
                                            selectedLayout.selectedLayoutId
                                        }
                                        onClick={switchLayout.bind(
                                            null,
                                            layout.id
                                        )}
                                    />
                                ))}
                            </LayoutsButtonStack>
                        </TinyExpandableArea>
                    </>
                )}

                {divider}
                <MenuItem
                    dense
                    onClick={() => {
                        setShowLayoutManager(true);
                        popupState.close();
                    }}
                    disableRipple
                    data-cy="LayoutMenuItem_LayoutManager"
                >
                    <ListItemIcon>
                        <Settings />
                    </ListItemIcon>
                    <Typography
                        className="item-text"
                        variant="h5"
                        component="div"
                    >
                        Manage layouts
                    </Typography>
                </MenuItem>
            </StyledLayoutDropdownMenu>
            {switchLayoutDialog}
            <LayoutManagerPanel
                show={showLayoutManager}
                onClose={() => setShowLayoutManager(false)}
            />
            <LayoutChangedSnackbar />
        </>
    );
};

export default LayoutsDropdownMenu;
