import {
    ColumnEvent,
    FilterChangedEvent,
    GridOptions,
} from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import {
    changeColumnOptions,
    changeFilterOptions,
    layoutsSelectors,
} from 'store/feature/layoutsSlice';
import { LayoutGridType } from 'store/feature/layoutsSlice/models';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { GridComponentRef } from './models';
import { GridUtils } from './utils';
import { userSelectors } from 'store/feature/userSlice';

// NOTE! Exhaustive deps are disabled on purpose and provided deps are correct for grid to work properly
/* eslint-disable  react-hooks/exhaustive-deps */

// TODO: replace with one from library after react 18 upgrade
function usePrevious<T>(value: T): T | undefined {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const FILTERS_DEBOUNCE = 500;
const COLUMN_DEBOUNCE = 500;

export interface GridControlContext {
    gridOptions: GridOptions;
}

export function useGridControl(
    gridType: LayoutGridType,
    gridRef: GridComponentRef
): GridControlContext {
    const dispatch = useAppDispatch();

    const filterOptions = useAppSelector(
        (state) => layoutsSelectors.filterOptions(state, gridType),
        _.isEqual
    );
    const onFilterChanged = useMemo(
        () =>
            _.debounce((e: FilterChangedEvent): void => {
                const changes = e.api.getFilterModel();
                dispatch(changeFilterOptions({ gridType, changes }));
            }, FILTERS_DEBOUNCE),
        [dispatch, gridType]
    );
    useEffect(() => {
        if (gridRef.current) {
            gridRef.current.api.setFilterModel(filterOptions);
            GridUtils.wrapFilterInstances(gridRef.current.api, filterOptions);
        }
    }, [filterOptions, gridRef]);

    const columnOptions = useAppSelector(
        (state) => layoutsSelectors.columnOptions(state, gridType),
        _.isEqual
    );
    const onColumnsChanged = useMemo(
        () =>
            _.debounce((e: ColumnEvent): void => {
                if (!GridUtils.isColumnEventEligible(e)) {
                    return;
                }
                const changes = e.columnApi.getColumnState();
                dispatch(changeColumnOptions({ gridType, changes }));
            }, COLUMN_DEBOUNCE),
        [dispatch, gridType]
    );

    const rowGroupIds = useAppSelector(
        (s) => layoutsSelectors.rowGroupIds(s, gridType),
        _.isEqual
    );
    useEffect(() => {
        if (gridRef.current && selectedLayoutId === prevSelectedLayoutId) {
            GridUtils.updateRowGroupsIfNeeded(gridRef.current, rowGroupIds);
        }
    }, [rowGroupIds]);

    const collapsedRowGroups = useAppSelector(
        (s) => layoutsSelectors.collapsedRowGroups(s, gridType),
        _.isEqual
    );
    // For future -> move grid-to-state collapsedRowGroups here from Fixtures/Orders Page

    const selectedLayoutId = useAppSelector(layoutsSelectors.selectedLayoutId);
    const prevSelectedLayoutId = usePrevious(selectedLayoutId);
    const newThemeEnabled = useAppSelector(userSelectors.isNewThemeEnabled);
    const hasPendingChanges = useAppSelector(
        layoutsSelectors.hasPendingChanges
    );
    const prevHasPendingChanges = usePrevious(hasPendingChanges);

    const loadColumnsToGrid = () => {
        if (gridRef.current) {
            gridRef.current.columnApi.setColumnState(columnOptions);
            GridUtils.initializeGridColumns(
                gridRef.current.columnApi,
                columnOptions
            );
            GridUtils.updateRowGroupsIfNeeded(gridRef.current, rowGroupIds);
            GridUtils.updateCollapsedRowGroups(
                gridRef.current,
                collapsedRowGroups
            );
        }
    };

    useEffect(() => {
        if (gridRef.current) {
            if (
                selectedLayoutId !== prevSelectedLayoutId ||
                (hasPendingChanges !== prevHasPendingChanges &&
                    !hasPendingChanges)
            ) {
                loadColumnsToGrid();
            }

            GridUtils.reloadRowGroupingIndentation(
                newThemeEnabled,
                gridRef.current.columnApi
            );
        }
    }, [
        selectedLayoutId,
        prevSelectedLayoutId,
        newThemeEnabled,
        hasPendingChanges,
        prevHasPendingChanges,
    ]);

    return {
        gridOptions: {
            onFilterChanged,
            onSortChanged: onColumnsChanged,
            onColumnMoved: onColumnsChanged,
            onColumnResized: (e) => {
                if (e.finished) {
                    onColumnsChanged(e);
                }
            },
            onColumnVisible: onColumnsChanged,
            onColumnPinned: onColumnsChanged,
            onColumnRowGroupChanged: () => {},
            onGridReady: (e) => {
                // First part of initialization is still in BasicGrid component
                GridUtils.updateRowGroupsIfNeeded(e, rowGroupIds);
                GridUtils.updateCollapsedRowGroups(e, collapsedRowGroups);
            },
            onFirstDataRendered: ({ api }) => {
                api.setFilterModel(filterOptions);
                GridUtils.wrapFilterInstances(api, filterOptions);
            },
        },
    };
}
